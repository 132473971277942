import { createContext, useContext } from 'react';

import netflixIcon from './assets/images/netflix-icon.png';
import facebookIcon from './assets/images/facebook-icon.png';
import uberIcon from './assets/images/uber-icon.png';

type ThemeT = {
  name: string;
  iconImg: string;
  bgGradient?: string;
  mainColor?: string;
  primary?: string;
  primaryOppt?: string;
};

export const commonData: {
  name: string;
} = {
  name: 'Youngjae Ji',
};

export type ContextT = {
  appContext: AppContextT;
  setAppContext: (param?: AppContextT) => void;
};

export type AppContextT = {
  selectedTheme: ThemeT;
};

export const themes = [
  {
    name: 'facebook',
    iconImg: facebookIcon,
    bgGradient: 'concord-img-gradient-light',
    mainColor: 'blue',
    primary: 'black',
    primaryOppt: 'white',
  },
  {
    name: 'netflix',
    iconImg: netflixIcon,
    bgGradient: 'concord-img-gradient',
    mainColor: 'red-600',
    primary: 'white',
    primaryOppt: 'black',
  },
  {
    name: 'uber',
    iconImg: uberIcon,
  },
] as ThemeT[];

export const defaultContext = {
  appContext: {
    selectedTheme: themes[0],
  },
  setAppContext: () => {},
};

export function useTheme(): ThemeT {
  const { appContext } = useContext(AppContext);

  const theme = appContext?.selectedTheme;
  return theme;
}

export function useThemeValue(key: string): string {
  const { appContext } = useContext(AppContext);

  const theme = appContext?.selectedTheme;
  // @ts-ignore: forcing key map to string
  const themeValue: string = theme[key] || '';

  return `${themeValue}`;
}

export const AppContext = createContext<ContextT>(defaultContext);
