import { useState } from "react";
import { AppContext, AppContextT, ContextT, defaultContext } from "./context";
import Layout from "./Layout";
import ThemeScssBuilder from "./ThemeScssBuilder";

export default function App() {
  const [appContext, setAppContext] = useState<AppContextT>(
    defaultContext.appContext
  );

  return (
    <AppContext.Provider value={{ appContext, setAppContext } as ContextT}>
      <Layout />
      <ThemeScssBuilder />
    </AppContext.Provider>
  );
}
