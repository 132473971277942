import { useState } from 'react';
import classNames from 'classnames';
import GlobeIcon from '../assets/icons/GlobeIcon';
import PrimaryText from '../PrimaryText';
import { ProjectT } from '../data';
import ProfileImg from './assets/favicon-96x96.png';
import { commonData } from '../context';

export default function Card({ project }: { project: ProjectT }) {
  const imgUrl = project.thumb?.default;

  const [open, setOpen] = useState(false);

  const desc = project.desc;
  const url = project.url;
  const validUrl = url.startsWith('http');

  const clickHandler = () => {
    if (validUrl) {
      window.open(url);
    }
  };

  return (
    <div
      key={project.name}
      className='relative w-full h-full overflow-hidden bg-white shadow rounded-md'
    >
      <PrimaryText
        className={classNames([
          { 'bg-white': !imgUrl },
          'w-full',
          'h-full flex flex-col items-center',
        ])}
      >
        <div className='flex w-full p-2'>
          <div className='relative flex-grow-0 flex-shrink-0'>
            <img
              alt='profile img'
              src={ProfileImg}
              className='rounded-full w-9 h-9'
            />
            <div className='absolute top-[23px] right-[-2px] w-3 h-3 bg-green-600 rounded-full border-2 border-white'></div>
          </div>
          <div className='flex flex-col flex-grow px-2'>
            <div className='font-bold'>{`${commonData.name}`}</div>
            <div className='text-xs text-gray-400'>
              {project.period}
              {' · '}
              <GlobeIcon className='inline w-3 h-3' />
            </div>
          </div>
        </div>
        <div className='w-full px-2 justify-start'>
          <div className='font-bold'>{project?.role?.join(', ')}</div>
        </div>
        <div className='bottom-0 left-0 w-full p-2 flex-1'>
          <div className={'pb-2'}>
            <div className={'text-sm'}>
              {project.name} -{' '}
              <span className='text-gray-400'>{project.subtitle}</span>
              <br />
              <br />
              {open ? desc : desc.slice(0, 120)}
              {!open && desc.length > 120 && '...'}
              {!open && desc.length > 120 && (
                <span
                  className='font-bold cursor-pointer'
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {' read more'}
                </span>
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames([
            'flex w-full',
            { 'cursor-pointer': validUrl },
          ])}
          onClick={clickHandler}
        >
          {imgUrl && (
            <img
              className='object-cover object-left-top w-full h-full border border-gray-100 max-h-72'
              src={imgUrl}
              alt={project.name}
            />
          )}
        </div>
        {url.startsWith('http') && (
          <div
            onClick={clickHandler}
            className={'cursor-pointer w-full bg-gray-200 p-2 text-sm'}
          >
            <div className={'text-gray-500 uppercase break-words text-xs'}>
              {url}
            </div>
            <div className={'font-bold text-lg leading-5'}>{project.name}</div>
            <div className={'text-gray-500 leading-4'}>{project.subtitle}</div>
          </div>
        )}
      </PrimaryText>
    </div>
  );
}
