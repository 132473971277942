import { Menu, Transition } from '@headlessui/react';
import { Fragment, useContext } from 'react';
import PaletteIcon from './assets/images/artist-palette_1f3a8.png';
import { ToastContainer, toast } from 'react-toastify';
import { AppContext, themes, useThemeValue } from './context';

export default function ThemeSelector() {
  const { setAppContext } = useContext(AppContext);

  const mainColor = useThemeValue('mainColor');

  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`
            inline-flex justify-center
            w-full px-4 py-2 text-sm font-medium text-white
            rounded-md
            z-30
            focus:outline-none focus-visible:ring-2
            focus-visible:ring-white focus-visible:ring-opacity-75
            bg-${mainColor}
          `}
          >
            <img className="w-5" src={PaletteIcon} alt="site theme" />
          </Menu.Button>
        </div>
        <ToastContainer
          pauseOnHover
          draggable
          position="top-right"
          autoClose={3000}
        />
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-30 w-auto mt-2 bg-white shadow-lg origin-top-right divide-y divide-gray-100 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="flex px-2 py-2 space-x-2">
              {themes.map((theme) => (
                <Menu.Item key={theme.name}>
                  {() => (
                    <button
                      className={
                        'overflow-hidden rounded-full w-7 h-7 items-center border-1 border-red-200'
                      }
                      onClick={() => {
                        if (!theme.mainColor) {
                          toast.warn('coming soon');
                          return;
                        }

                        setAppContext({
                          selectedTheme: theme,
                        });
                      }}
                    >
                      <img src={theme.iconImg} alt={theme.name} />
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
}
