export const profile = {
  name: 'Joshua Younjgae Ji',
  title: 'Senior Software Engineer',
  email: 'zirho6@gmail.com',
  cell: '323-382-3979',
  address: 'Upland, CA',
  github: 'https://github.com/zirho/',
  blog: 'http://zirho.github.io/',
  portfolio: 'https://zirho.github.io/portfolio2/',
};

export type ProjectT = {
  name: string;
  subtitle: string;
  url: string;
  location: string;
  period: string;
  thumb?: { default: string };
  role: string[];
  skills: string[];
  desc: string;
};

export const projects: ProjectT[] = [
  // {
  //   name: 'ppp',
  //   subtitle: 'ppp',
  //   url: 'ppp',
  //   location: 'ppp',
  //   period: 'ppp',
  //   thumb: './assets/images/projects/ppp.png',
  //   role: ['Senior Software Engineer', 'Project Manager'],
  //   skills: ['JavaScript', 'Node.js', 'react', 'webpack', 'Flux (redux)', 'less', 'HMR'],
  //   desc: ''
  // },
  {
    name: 'Twenty Seconds app',
    subtitle: 'Have I tried this one before?',
    url: 'https://twentyseconds.app',
    period: 'Apr 2022 - May 2022',
    thumb: require('./assets/images/projects/ts-mobile.png'),
    role: ['Founder'],
    location: 'Los Angeles, US',
    skills: [
      'flutter',
      'dart',
      'React',
      'typescript',
      'Node.js',
      'mysql',
      'GraphQL',
      'Apollo',
    ],
    desc: ' “Have I tried this one before?” “Was this the brand that was too salty?” Wonder no more! Twenty Seconds will save you time and money by keeping track of your hits and misses so you don’t buy the same unappetizing product again and again.',
  },
  {
    name: 'OmniAPI',
    subtitle: 'PDF processing all the way',
    url: 'https://omniapi.xyz',
    period: 'Nov 2022 (just in 5 days)',
    thumb: require('./assets/images/projects/omni-api.png'),
    role: ['Founder'],
    location: 'Los Angeles, US',
    skills: ['React', 'typescript', 'Node.js', 'mysql', 'GraphQL', 'Apollo'],
    desc: 'PDF and Image OCR solution for light weight applications. The service accept PDFs or Images and convert them into image thumbnails and let users search for keywords across all files. Plant to expand to make it available via APIs.',
  },
  {
    name: 'Bard (internal testing)',
    subtitle: 'Short format audio SNS web application',
    url: 'https://bard.am',
    period: 'Sep 2022',
    thumb: require('./assets/images/projects/bard-mobile.png'),
    role: ['Full Stack Software Engineer'],
    location: 'Los Angeles, US',
    skills: ['React', 'typescript', 'Node.js', 'mysql', 'GraphQL', 'Apollo'],
    desc: 'Designed and built the architecture. Social media platform that people can share their short format (< 5min) audio contents on. Utilize strapi and graphql as a backend service using gcloud mysql as database.',
  },
  {
    name: 'scalestate',
    subtitle: 'Financial auditing fully automated',
    url: 'https://scalestate.io',
    period: 'Sep 2022 - Nov 2022',
    thumb: require('./assets/images/projects/ss-landing-mobile.png'),
    role: ['Full Stack Lead'],
    location: 'Los Angeles, US',
    skills: ['React', 'typescript', 'Node.js', 'mysql', 'GraphQL', 'Apollo'],
    desc: 'Designed and architected the sustem using aws and gcloud. It uses aws OCR solution to extract data from files and compare with CSV samples. I built all the business logic to compare variety types of values and come up with differences.',
  },
  {
    name: 'Aquadesk',
    subtitle: 'web + ios + android, hobby project',
    url: 'https://www.aquadesk.com/articles/download-aquadesk-android-ios-apps',
    period: 'Feb 2017',
    thumb: require('./assets/images/projects/aqua2.png'),
    role: ['Full Stack Software Engineer'],
    location: 'San Francisco, US',
    skills: [
      'React',
      'Redux',
      'Node.js',
      'Flutter',
      'mongodb',
      'GraphQL',
      'Apollo',
      'webpack',
      'SSR',
    ],
    desc: 'This is my hobby project. Demonstrative and laboratory work for applying cutting edge tech stacks thriving https://12factor.net/ elements. Data modeling in no-sql, fully responsive and pixel perfect UI/UX implementation. Available on the App store and the Google Play store. All done by me from designing on figma to implementing web, ios and android apps.',
  },
  {
    name: 'SteelHouse product catalog',
    subtitle: 'Chrome Extension for team members',
    url: 'Url not available',
    location: 'Culver City, California',
    period: 'Jan 2017',
    thumb: require('./assets/images/projects/steel-ext.jpg'),
    role: ['Senior Software Engineer'],
    skills: ['JavaScript', 'react', 'webpack', 'redux', 'css', 'HMR'],
    desc: 'Built a simple chrome extension for easy access to all project urls in QA, Staging and Production environments. This is built as my own side project after office hours. This is basically a webapp, so mostly anything can be possible.',
  },
  {
    name: 'SteelHouse PixelUI',
    subtitle: 'Marketing pixcel, tag collecting library',
    url: 'https://pixel.steelhouse.com',
    location: 'Culver City, California',
    period: 'Dec 2016 - Jul 2017 (1 year 2 months)',
    thumb: require('./assets/images/projects/steelhouse2.png'),
    role: ['Senior Software Engineer'],
    skills: [
      'JavaScript',
      'Node.js',
      'react',
      'webpack',
      'Flux (redux)',
      'less',
      'HMR',
    ],
    desc: 'Built marketing pixel(crawler) JavaScript code and the integration apps on top three e-commerce solutions which include Shopify, Magento and Prestashop via their API or modules. Built user facing integration and walkthrough guide site with react and Node.js',
  },
  {
    name: 'SteelHouse Adsuite',
    subtitle: 'Revamping monolithic web app and breaking it into small peices',
    url: 'https://ui.steelhouse.com',
    location: 'Culver City, California',
    period: 'Dec 2016 - Jul 2017 (1 year 2 months)',
    thumb: require('./assets/images/projects/steelhouse1.png'),
    role: ['Senior Software Engineer'],
    skills: [
      'JavaScript',
      'PHP',
      'jQuery',
      'react',
      'redux',
      'webpack',
      'CSS',
      'Sass',
      'HMR',
    ],
    desc: 'Maintained and refactored a legacy web app with the latest tech stack, such as JS ES6 with HMR-enabled on webpack. Start adding unit tests by decoupling presentational and logical components. Integrate the system with external ads apis such as Facebook, Twitter, Instagram. Refactor workflow of react-redux-based apps to have HMR enabled with webpack.',
  },
  {
    name: 'Dr Pure Natural',
    subtitle: 'E-commerce for health care products.',
    url: 'https://www.drpurenatural.com',
    location: 'La Mirada, California',
    period: 'Nov 2015 - June 2016 (8 months)',
    thumb: require('./assets/images/projects/2.png'),
    role: ['Senior Software Engineer', 'Project Manager'],
    skills: [
      'PHP',
      'Magento',
      'jQuery',
      'JavaScript',
      'CSS',
      'Sass',
      'CoffeeScript',
      'Bootstrap',
    ],
    desc: 'Developed e-commerce website with magento on AWS platform. Front-end(jQuery, CoffeeScript, Sass, CSS3, HTML5) and back-end(PHP, MySQL) development including setting up servers on cloud services. Customized extensions per customer specifications. Utilized distributed servers from multiple location to serve international customers with multiple languages. Developed automatic polling shipment information module using vendors’ APIs. Developed automatic blockage detection to change product urls through separate located servers.',
  },
  {
    name: 'SIE Soft',
    subtitle: 'Revolutionizing a medical record platform',
    url: 'Url not available',
    location: 'Los Anageles, California',
    period: 'May 2015 - September 2015 (5 months)',
    // thumb:require( './assets/images/projects/ppp.png'),
    role: ['Software Engineer'],
    skills: ['PHP', 'Laravel', 'jQuery', 'JavaScript', 'Scss', 'Bootstrap'],
    desc: 'Developed EMR(Electronic medical record) software front-end(jQuery) and back-end(PHP, Laravel). Worked with task runners for transpiling or bundling the result deriving continuous integration and delivery. Made customizable page template plugin with jQuery. Built system with Laravel powered server side PHP scripts and migration tool. ',
  },
  {
    name: 'MightyFine',
    subtitle: 'e-commerce web application for designers and fans',
    url: 'http://www.welovefine.com',
    location: 'Los Angeles, California',
    period: 'Jan 2011 - Apr 2015 (5 years)',
    thumb: require('./assets/images/projects/1.png'),
    role: ['Senior Software Engineer', 'Project Manager'],
    skills: [
      'PHP',
      'Prestashop',
      'jQuery',
      'JavaScript',
      'CSS',
      'Sass',
      'CoffeeScript',
      'Bootstrap',
    ],
    desc: 'Develope nd maintained e-commerce web app which is customized for a variety of functionalities. Worked with many designers and managers to build design ecosystem. Built front-end(jQuery) and back-end(PHP, Prestashop), maintained distributed servers(AWS) and integrated it with ERP solution. Built contest ecosystem with real time notification feature. The system can handle over 1,000 submits and over 10k ratings per contest. Made a rate moderate system for faulty ratings based on geological information.',
  },
  {
    name: 'DeView 2011',
    subtitle: 'event web app for the biggist korean dev conference by nhn',
    url: 'http://deview.kr/2011/',
    location: 'Bundang, South Korea',
    period: 'January 2011 - February 2011 (2 months)',
    thumb: require('./assets/images/projects/deview2.png'),
    role: ['Senior Software Engineer', 'Project Manager'],
    skills: ['PHP', 'jQuery', 'JavaScript', 'CSS'],
    desc: 'Lead NHN Deview 2011 PC, Mobile web application development. Collaborated with managers and designers to develop one of the huge dev-conferences, Deview, in Korea. Developed mobile compliant web app for real time broadcasting. ',
  },
  {
    name: 'Forever21',
    subtitle: 'desktop software for printing thousands of product tags',
    url: 'Url not available',
    location: 'Los Angeles, California',
    period: 'July 2010 - February 2011 (8 months)',
    role: ['Internship'],
    skills: ['VisualBasic', 'C#'],
    desc: 'Maintained VisualBasic based ESM, CMS, and label printing software.',
  },
  {
    name: 'PipingXE',
    subtitle: 'CMS module that allows you copy website in seconds',
    url: 'Url not available',
    location: 'Seoul, Korea',
    period: 'Feb, 2011',
    thumb: require('./assets/images/projects/pipingxe.png'),
    role: ['Senior Software Engineer', 'Project Manager'],
    skills: ['PHP', 'XE (CMS)', 'jQuery', 'JavaScript', 'CSS'],
    desc: 'Developed a module cloning websites built on top of XpressEngine CMS. It has been submitted to XE open-source competition.',
  },
  {
    name: 'Tokyo Institute of Technology Community',
    subtitle: 'online community for students',
    url: 'http://www.titechkorea.net/',
    location: 'Seoul, Korea',
    period: 'June, 2011',
    thumb: require('./assets/images/projects/dongkyung.png'),
    role: ['Senior Software Engineer', 'Project Manager'],
    skills: ['PHP', 'XE (CMS)', 'jQuery', 'JavaScript', 'CSS'],
    desc: 'Developed CMS website for student community. Puglishing from PSD (photoshop) files. Build themes, skins, modules for it',
  },
  {
    name: 'Cube Entertainment',
    subtitle: 'One of the biggest entertainment companies in Korea',
    url: 'http://www.cubeent.co.kr/',
    location: 'Seoul, Korea',
    period: 'Jan, 2011',
    thumb: require('./assets/images/projects/cube.png'),
    role: ['Senior Software Engineer'],
    skills: ['PHP', 'XE (CMS)', 'jQuery', 'JavaScript', 'CSS'],
    desc: 'Developed CMS website for an entertainment company called CubeEntertainment. Puglishing from PSD (photoshop) files. Build themes, skins, modules for it',
  },
  {
    name: 'iHandong',
    subtitle: 'intro iOS application for my school',
    url: 'Url not available',
    location: 'Pohang, Korea',
    period: 'Apr 2010 - Jun 2010 (a month)',
    thumb: require('./assets/images/projects/ihandong.jpg'),
    role: ['Lead Software Engineer', 'Project Manager'],
    skills: ['Objective-C', 'Xcode'],
    desc: 'Developed an introductory app with colleagues. Details are included in the link page.',
  },
  {
    name: 'HGUBus',
    subtitle: 'live bus schedule app for my school',
    url: 'Url not available',
    location: 'Pohang, Korea',
    period: 'Apr 2010',
    thumb: require('./assets/images/projects/bus.jpg'),
    role: ['Lead Software Engineer', 'Project Manager'],
    skills: ['Objective-C', 'Xcode', 'Photoshop'],
    desc: 'Developed a school bus schedule app. Details are included in the link page.',
  },
  {
    name: 'HGUBob',
    subtitle: 'realtime meal menu & schedule app for my school',
    url: 'Url not available',
    location: 'Pohang, Korea',
    period: 'Apr 2010',
    thumb: require('./assets/images/projects/bob.jpg'),
    role: ['Lead Software Engineer', 'Project Manager'],
    skills: ['Objective-C', 'Xcode', 'Photoshop'],
    desc: 'Developed an menu information app. Details are included in the link page.',
  },
  {
    name: 'XE widgets',
    subtitle: 'convenient and beautiful CMS modules works for XE',
    url: 'Url not available',
    location: 'Seoul, Korea',
    period: 'Mar 2010',
    thumb: require('./assets/images/projects/widgetxe.png'),
    role: ['Senior Software Engineer', 'Project Manager'],
    skills: ['PHP', 'XE (CMS)', 'jQuery', 'JavaScript', 'CSS'],
    desc: 'Developed XE (CMS) widgets and layouts. Lots of jQuery plugins are used for the job.',
  },
  {
    name: 'WizardXE',
    subtitle: 'CMS module that provides seamless website creating experience',
    url: 'Url not available',
    location: 'Seoul, Korea',
    period: 'Jan 2010',
    thumb: require('./assets/images/projects/wizardxe.png'),
    role: ['Senior Software Engineer', 'Project Manager'],
    skills: ['PHP', 'XE (CMS)', 'jQuery', 'JavaScript', 'CSS'],
    desc: 'Developed XE (CMS) module. This one is the winner of the open source competition called XE CONTEST. https://www.xpressengine.com/xe_contest_2010',
  },
  {
    name: 'Image slider library',
    subtitle: 'jQuery Plugin loved by many jQuery projects',
    url: 'Url not available',
    location: 'Seoul, Korea',
    period: 'Nov 2009',
    thumb: require('./assets/images/projects/jquery.plugin.png'),
    role: ['Senior Software Engineer'],
    skills: ['jQuery', 'JavaScript', 'CSS'],
    desc: 'Image fading slider is a jQuery plugin. That demonstrates images with effects which used to be only available in adobe flash in the past.',
  },
];
