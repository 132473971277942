import { ReactNode } from "react";
import { useThemeValue } from "./context";

export default function CtaText({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const mainColor = useThemeValue("mainColor");

  return (
    <span className={`text-${mainColor} ${className || ""}`}>{children}</span>
  );
}
