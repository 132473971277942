// import isString from "lodash.isstring";
// import { themes } from "./context";

export default function ThemeScssBuilder() {
  // TODO figure out dynamic scss builder
  // dynamically inject tailwind css names to make it available
  // but it does not work
  // scss analizes it statically
  // const cn = themes.reduce<string[]>((clss, theme) => {
  //   let key: keyof typeof theme;

  //   for (key in theme) {
  //     if (["name", "iconImg", "bgGradient"].includes(key)) {
  //       continue;
  //     }

  //     const value = theme[key];

  //     if (isString(value)) {
  //       clss.push(value);
  //       clss.push(`text-${value}`);
  //       clss.push(`bg-${value}`);
  //     }
  //   }

  //   return clss;
  // }, []);

  return (
    <div
      className="w-0 h-0 red-600 text-red-600 bg-red-600 white text-white
    bg-white black text-black bg-black blue text-blue bg-blue black 
    text-black bg-black white text-white bg-white w-7 w-8 w-9 w-10 h-7 h-8 h-9 h-10"
    ></div>
  );
}
