import Contents from "./Contents";
import { useThemeValue } from "./context";
import Header from "./header";

export default function Layout() {
  const primaryOppt = useThemeValue("primaryOppt");

  return (
    <div className={`min-h-screen bg-${primaryOppt}`}>
      <div className={`w-full min-h-screen`}>
        <div className="relative mx-auto">
          <Header />
          <Contents />
        </div>
      </div>
    </div>
  );
}
