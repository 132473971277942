import { ReactNode } from "react";
import { useThemeValue } from "./context";

export default function PrimaryText({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const primary = useThemeValue("primary");

  return <div className={`text-${primary} ${className || ""}`}>{children}</div>;
}
