import ThumbsUpEmoji from "./assets/icons/thumbs-up_1f44d.png";
import RedHeartEmoji from "./assets/icons/red-heart_2764-fe0f.png";
import FishEmoji from "./assets/icons/fish_1f41f.png";
import FloatingIcon from "./fb/FloatingIcon";
import PrimaryText from "./PrimaryText";
import random from "lodash.random";

const emojis = [ThumbsUpEmoji, RedHeartEmoji, FishEmoji];

// const iconCount = 3;
const iconCount = 30;
const iconArr = Array(iconCount)
  .fill(null)
  .map((_, id) => {
    const idx = random(0, emojis.length - 1);

    return { id, icon: emojis[idx] };
  });

export default function FBHeader() {
  return (
    <div>
      <PrimaryText className="relative min-h-[13rem] z-10 w-full p-5 py-9">
        <div className={"font-bold text-center text-3xl"}>
          Unlimited cutting edge skills, and more.
        </div>
        <div className={"py-6 text-center text-md"}>
          Hit me up anytime. Enjoy outcome everytime.
        </div>
      </PrimaryText>

      {/* background banner */}
      <div className="fixed top-0 z-0 w-full overflow-hidden h-1/2">
        {iconArr.map((item) => (
          <FloatingIcon key={item.id} icon={item.icon} />
        ))}
      </div>
    </div>
  );
}
