import shuffle from 'lodash.shuffle';
import Card from './Card';
import { projects } from './data';
import LandingHeader from './LandingHeader';
import { useThemeValue } from './context';

const fontSetSize = 12;
const fontSet = Array(fontSetSize)
  .fill(null)
  .map((_, i) => i);
const fontLists = shuffle([...fontSet, ...fontSet]);

export default function Contents() {
  const name = useThemeValue('name');

  const cls = [];
  const clsWrap = [];
  if (name === 'netflix') {
    cls.push('p-5');
  } else if (name === 'facebook') {
    cls.push('p-2');
    clsWrap.push('bg-gray-100');
  }

  return (
    <>
      <LandingHeader />
      <div
        className={`pt-4 ${clsWrap.join(' ')}`}
      >
        <div
          className={`${cls.join(
            ' ',
          )} mx-auto text-blue max-w-screen-lg h-30 grid grid-cols-1 gap-x-5 gap-y-9 sm:grid-cols-2 md:grid-cols-3`}
        >
          {projects.map((project, idx) => {
            const font1 = `${fontLists[idx % fontSetSize]}`;
            const font2 = `${fontLists[(idx + 3) % fontSetSize]}`;

            return (
              <Card
                key={project.name}
                project={project}
                font1={font1}
                font2={font2}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}
