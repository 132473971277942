import cardBg from "./assets/images/nf-bg-40p.jpg";
import { useThemeValue } from "./context";
import PrimaryText from "./PrimaryText";
import FBHeader from "./FBHeader";

export default function LandingHeader() {
  const bgGradient = useThemeValue("bgGradient");
  const name = useThemeValue("name");

  if (name === "facebook") {
    return <FBHeader />;
  }

  return (
    <div>
      <PrimaryText className="relative min-h-[13rem] z-10 w-full p-5 py-9">
        <div className={"font-bold text-center text-3xl"}>
          Unlimited cutting edge skills, and more.
        </div>
        <div className={"py-6 text-center text-md"}>
          Hit me up anytime. Enjoy outcome everytime.
        </div>
      </PrimaryText>

      {/* background banner */}
      <div className="absolute top-0 z-0 w-full h-screen overflow-hidden max-h-72">
        <img
          className="object-cover w-full h-full"
          src={cardBg}
          alt="project cards"
        />
        <div className={`absolute top-0 w-full h-full ${bgGradient}`}></div>
      </div>
    </div>
  );
}
