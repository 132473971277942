import classNames from "classnames";
import OfflineIcon from "../assets/icons/OfflineIcon";
import PrimaryText from "../PrimaryText";
import { ProjectT } from "../data";

export default function Card({
  project,
  font1,
  font2,
}: {
  project: ProjectT;
  font1: string;
  font2: string;
}) {
  const imgUrl = project.thumb?.default;

  return (
    <div
      key={project.name}
      className="relative w-full h-full p-5 md:p-0 h-[28rem]"
    >
      <PrimaryText
        className={classNames([
          { "bg-white": !imgUrl },
          "w-full",
          "h-full flex justify-center items-center",
        ])}
      >
        {imgUrl ? (
          <img
            className="object-cover object-left-top w-full h-full"
            src={imgUrl}
            alt={project.name}
          />
        ) : (
          <OfflineIcon className="text-black" />
        )}
        <div className="absolute bottom-0 left-0 w-full p-5 text-white md:p-0">
          <div
            className={"p-4 pt-7"}
            style={{
              backgroundImage:
                "linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0))",
            }}
          >
            <div className={`truncate text-xl font-${font1}`}>
              {project.name}
            </div>
            <div className={`opacity-40 truncate text-md font-${font2}`}>
              {project.subtitle}
            </div>
          </div>
        </div>
      </PrimaryText>
    </div>
  );
}
