import { MenuIcon } from "@heroicons/react/outline";
import FBYoungae from "./assets/images/facebook-youngjae.png";
import NetflixYoungae from "./assets/images/netflix-youngjae.png";
import { useThemeValue } from "./context";
import CtaText from "./CtaText";
import ThemeSelector from "./ThemeSelector";

export default function Header() {
  const primary = useThemeValue("primary");
  const name = useThemeValue("name");

  return (
    <div className="relative z-20 flex justify-between p-4 mx-auto max-w-screen-lg">
      <div className="flex items-center justify-start">
        <img
          src={name === "netflix" ? NetflixYoungae : FBYoungae}
          alt="Youngae loves netflix"
          className="w-[90px]"
        />
      </div>

      <div className="justify-end hidden">
        <MenuIcon className={`text-${primary}`} />
      </div>

      <div className="flex items-center space-x-5">
        <a
          href="https://www.notion.so/Youngjae-Ji-52b5d87a135e40b493c75c26e3610910"
          rel="noreferrer"
          target="_blank"
          className="text-base font-medium"
        >
          <CtaText>About</CtaText>
        </a>
        <ThemeSelector />
      </div>
    </div>
  );
}
