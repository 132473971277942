import * as d3 from "d3-ease";
import random from "lodash.random";
import { useEffect } from "react";
import { animated, config, useSpring } from "react-spring";

export default function FloatingIcon({ icon }: { icon: string }) {
  const offsetX = random(0, 900);
  const offsetY = random(0, 100);
  const delayX = random(0, 100);
  const durationY = random(1700, 3500);
  const delayStart = random(0, 1200);
  const size = random(7, 10);

  const oy = 450 + offsetY;
  const ox = 200;
  const oOpacity = 0.5;

  const [{ y, opacity }, api] = useSpring(() => {
    return {
      from: { y: oy, opacity: oOpacity },
      config: {
        ...config.default,
        duration: durationY,
        easing: d3.easeCircleIn,
      },
    };
  });

  const [{ x }, xapi] = useSpring(() => ({
    from: { x: ox + offsetX },
    loop: { reverse: true },
    to: { x: 0 + offsetX },
    delay: delayX,
    config: {
      ...config.stiff,
      duration: 500,
      velocity: 1,
      easing: d3.easeQuadInOut,
    },
  }));

  const style = {
    // width: 80,
    // height: 80,
    // backgroundColor: "#46e891",
    // borderRadius: 16,
    x,
    y,
    opacity,
  };

  useEffect(() => {
    xapi.pause();

    setTimeout(() => {
      startAnimation();
    }, delayStart);
    // eslint-disable-next-line
  }, []);

  const startAnimation = () => {
    api.set({
      y: oy,
      opacity: oOpacity,
    });
    api.start({
      y: -200,
      opacity: 1,
      onRest: () => {
        xapi.pause();
        setTimeout(() => {
          startAnimation();
        }, delayStart);
      },
    });
    xapi.resume();
  };

  return (
    <animated.div className="absolute" style={style} onClick={startAnimation}>
      <img className={`w-${size} h-${size}`} src={icon} alt="floating icon" />
    </animated.div>
  );
}
