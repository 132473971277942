import NetflixCard from "./nf/Card";
import FbCard from "./fb/Card";
import { useThemeValue } from './context';

export default function Card(props: any) {
  const name = useThemeValue("name");

  if (name === 'facebook') {
    return <FbCard {...props} />
  }

  if (name === 'netflix') {
    return <NetflixCard {...props} />
  }

  return null;
}
